<template>
  <div v-if="ajaxCompleted">
    <h1 class="title" v-if="!create">Edit Restic backup</h1>
    <h1 class="title" v-else>Create new Restic backup</h1>
    <grid>
      <grid-col size="6">
        <card title="Enter properties">
          <Property title="Device" v-if="create">
            <select
              name="device"
              class="input-select"
              @change="setResticName($event)"
              v-if="devices && devices.length"
            >
              <option value="">Select device</option>
              <option v-for="device in devices" :key="device._id" :value="device._id">
                {{ device.name }}
              </option>
            </select>
            <span v-else
              >There are no eligible Restic devices available (without config or enabled)</span
            >
          </Property>

          <Property title="Device" v-else>
            <span>{{ restic.device.name }}</span>
          </Property>

          <Property title="Server">
            <span v-if="!create && !restic.initialized">
              <p>
                Since this Restic has not yet been initialized, you can link it to another server
              </p>
            </span>
            <select
              name="server"
              class="input-select"
              v-model="restic.server._id"
              v-if="create || (!create && !restic.initialized)"
            >
              <option value="">Select server</option>
              <option v-for="server in servers" :key="server._id" :value="server._id">
                {{ server.name }}
              </option>
            </select>
            <span v-else>
              {{ restic.server.name }}
            </span>
          </Property>

          <Property title="Paths">
            <select name="paths" class="input-select" v-model="restic.paths._id">
              <option value="">Select path</option>
              <option v-for="path in paths" :key="path._id" :value="path._id">
                {{ path.name }}
              </option>
            </select>
          </Property>

          <Property title="Checks">
            <select name="checks" class="input-select" v-model="restic.checks._id">
              <option value="">Select check</option>
              <option v-for="check in checks" :key="check._id" :value="check._id">
                {{ check.name }}
              </option>
            </select>
          </Property>

          <Property title="UUID">
            <input
              type="text"
              placeholder="UUID"
              class="input-text"
              v-model="restic.uuid"
              v-if="create"
            />
            <span v-else>{{ restic.uuid }}</span>
          </Property>

          <Property title="Name">
            <input type="text" placeholder="Name" class="input-text" v-model="restic.name" />
          </Property>

          <Property title="Available">
            <label
              ><input type="checkbox" v-model="restic.available" /> Yes, backups are enabled</label
            >
          </Property>

          <Property title="Schedule">
            <label
              ><input type="checkbox" v-model="restic.schedule.enabled" /> Backup this server every
              interval</label
            >
          </Property>

          <Property title="Schedule Interval" v-if="restic.schedule.enabled">
            <select name="paths" class="input-select" v-model="restic.schedule.configuredInterval">
              <option :value="1800">Every thirty minutes</option>
              <option :value="3600">Every hour</option>
              <option :value="7200">Every two hours</option>
              <option :value="14400">Every four hour</option>
              <option :value="21600">Every six hour</option>
              <option :value="43200">Every twelve hour</option>
              <option :value="86400">Every day</option>
            </select>
          </Property>

          <Property title="Health Check" v-if="restic.schedule.enabled">
            <label v-if="!restic.healthcheck">
              <input type="checkbox" v-model="createHC" /> Create Healthcheck on save
            </label>
            <span v-else>
              {{ restic.healthcheck.ping }}
            </span>
          </Property>

          <Property>
            <base-button
              v-on:click="onSave"
              :disabled="
                !restic.device ||
                !restic.device._id ||
                !restic.server ||
                !restic.server._id ||
                !restic.paths ||
                !restic.paths._id
              "
              iconType="CHECK"
              title="Save changes"
            />
            <base-button v-on:click="onCancel" class="right" type="THEMED" title="Cancel" />
          </Property>
        </card>
      </grid-col>
      <grid-col size="6" v-if="restic.paths._id || restic.server._id">
        <ResticServer
          v-if="servers && restic.server._id"
          :server="
            servers
              .filter((p) => {
                return p._id === restic.server._id;
              })
              .shift()
          "
        />
        <ResticPaths
          v-if="paths && restic.paths._id"
          :paths="
            paths
              .filter((p) => {
                return p._id === restic.paths._id;
              })
              .shift()
          "
        />
        <ResticChecks
          v-if="checks && restic.checks._id"
          :checks="
            checks
              .filter((p) => {
                return p._id === restic.checks._id;
              })
              .shift()
          "
        />
      </grid-col>
    </grid>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';
import Property from '@/components/Util/Property.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseComponent from '../Base.vue';
import ResticPaths from './Paths.vue';
import ResticServer from './Server.vue';
import ResticChecks from './Checks.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'CreateEditRestic',
  extends: BaseComponent,
  components: {
    Property,
    ResticPaths,
    ResticServer,
    ResticChecks,
    Card,
    Grid,
    GridCol,
    BaseButton,
  },

  props: ['resticId'],
  watch: {
    resticId(newVal) {
      this.getRestic(newVal);
    },
  },
  data() {
    return {
      ajaxCompleted: false,
      createHC: true,
      create: true,
      restic: null,
      servers: [],
      devices: [],
      paths: [],
      checks: [],
    };
  },
  methods: {
    onCancel() {
      if (this.create) {
        this.$router.push('/restic');
      } else {
        this.$router.push(`/restic/${this.restic._id}`);
      }
      this.$emit('cancel');
      this.$root.$emit('reloadTable');
      this.selected = null;
    },
    async onSave() {
      if (typeof this.restic && this.restic._id) {
        await this.handleOnEdit();
      } else {
        await this.handleOnSave();
      }
    },
    async handleOnEdit() {
      const response = await Utils.fetch(
        `/api/v1/restics/${this.restic._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            restic: this.restic,
            createHC: this.createHC,
          }),
        },
        this,
      ).then((res) => res.json());

      if (response.success) {
        this.$noty.success('Saved restic');
        this.$emit('saved');
        await this.$router.push('/restic/');
      } else {
        this.$noty.warning(response.message);
      }
    },
    async handleOnSave() {
      const response = await Utils.post(
        '/api/v1/restics',
        {
          server: this.restic.server,
          device: this.restic.device,
          paths: this.restic.paths,
          name: this.restic.name,
          uuid: this.restic.uuid,
          scheduleEnabled: this.restic.schedule.enabled,
          scheduleInterval: this.restic.schedule.configuredInterval,
          createHC: this.createHC,
        },
        this,
      );

      if (response.success) {
        this.$noty.success('Saved restic');
        this.$nextTick(() => {
          this.onCancel();
          this.$emit('saved', this.$event, response.restic._id);
        });
      } else {
        this.$noty.warning(response.message);
      }
    },
    getServers() {
      return Utils.fetch('/api/v1/restics/helper/servers', {}, this).then((res) => res.json());
    },
    getDevices() {
      return Utils.fetch('/api/v1/restics/helper/eligible', {}, this).then((res) => res.json());
    },
    getPaths() {
      return Utils.fetch('/api/v1/restics/helper/paths', {}, this).then((res) => res.json());
    },
    getChecks() {
      return Utils.fetch('/api/v1/restics/helper/checks', {}, this).then((res) => res.json());
    },
    async getRestic(id) {
      if (id === -1) {
        const response = await Utils.fetch('/api/v1/restics/helper/empty', {}, this).then((res) =>
          res.json(),
        );
        if (response.success) {
          this.ajaxCompleted = true;
          this.restic = response.restic;
          this.create = true;
        }
      } else {
        const response = await Utils.fetch(`/api/v1/restics/${id}`, {}, this).then((res) =>
          res.json(),
        );
        if (response.success) {
          this.ajaxCompleted = true;
          this.restic = response.restic;
          this.create = false;
          // make sure checks is always defined to satisfy editing a restic without a check
          if (!this.restic.checks) {
            this.restic.checks = { _id: null };
          }
        }
      }

      if (!this.restic.schedule) {
        this.restic.schedule.enabled = false;
        this.restic.schedule.configuredInterval = null;
      }
    },
    async setResticName(e) {
      if (this.create) {
        const name = e.target.options[e.target.options.selectedIndex].text;
        this.restic.name = e.target.value ? name : null;
        this.restic.device._id = e.target.value;
      }
    },
  },
  mounted() {
    this.getRestic(this.resticId).then(() => {
      this.getServers().then((res) => {
        this.servers = res.servers;
      });
      this.getDevices().then((res) => {
        this.devices = res.devices;
      });
      this.getPaths().then((res) => {
        this.paths = res.paths;
      });
      this.getChecks().then((res) => {
        this.checks = res.checks;
      });
    });
  },
};
</script>
