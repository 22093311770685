<template>
  <card :title="title" :collapse="true" v-if="restic && restic.device && restic.device.system">
    Instructions for OS: {{ restic.device.system.os }}
    <div v-if="restic.device.system.os === 'windows'">
      <p>Prepare environment</p>
      <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
        $env:RESTIC_REPOSITORY="{{
          restic.server.uri.client.replace(/%\(uuid\)s/, restic.uuid)
        }}"<br />
        $env:RESTIC_PASSWORD="{{ restic.keys.admin }}"<br />
      </code>
      <p>Snapshots</p>
      <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
        restic snapshots
      </code>
      <p>Files in latest snapshot</p>
      <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
        restic --json ls latest | jq . | less
      </code>
      <p>Remove latest snapshot</p>
      <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
        restic forget latest
      </code>
      <p>Restore latest snapshot (all files)</p>
      <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
        New-Item -ItemType Directory -Force -Path C:\Screencom\Restore | Out-Null<br />
        restic restore --no-lock -t C:\Screencom\Restore latest
      </code>
      <p>Restore latest snapshot (only runtime)</p>
      <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
        New-Item -ItemType Directory -Force -Path C:\Screencom\Restore-runtime | Out-Null<br />
        restic restore --no-lock -i runtime -t C:\Screencom\Restore-runtime latest
      </code>
      <p>All commands use the admin key</p>
    </div>
    <div v-else>
      <p>Prepare environment</p>
      <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
        export RESTIC_REPOSITORY="{{
          restic.server.uri.client.replace(/%\(uuid\)s/, restic.uuid)
        }}"<br />
        export RESTIC_PASSWORD="{{ restic.keys.admin }}"
      </code>
      <p>Snapshots</p>
      <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
        restic snapshots
      </code>
      <p>Files in latest snapshot</p>
      <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
        restic --json ls latest | jq . | less
      </code>
      <p>Remove latest snapshot</p>
      <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
        restic forget latest
      </code>
      <p>Files in latest snapshot via API</p>
      <code class="debug" @click="copyDebug($event)" title="Click to copy to clipboard">
        curl -H 'X-Restic-Key: {{ restic.keys.admin }}' \<br />
        -H 'Authorization: {{ restic.server.token }}' \<br />
        {{ restic.server.uri.api }}/restic/{{ restic.uuid }}/files/latest
      </code>
      <p>All commands use the admin key</p>
    </div>
  </card>
</template>

<script>
import Card from '../Card/Card.vue';

export default {
  name: 'ResticDebug',
  props: ['title', 'restic'],
  components: {
    Card,
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {},
  methods: {
    copyDebug(event) {
      this.$clipboard(event.target.innerText);
      this.$noty.success('Copied command to clipboard', {
        timeout: 2000,
      });
    },
  },
  mounted() {
    this.ready = true;
  },
};
</script>

<style lang="scss" scoped>
code.debug {
  white-space: nowrap;
  max-width: 100%;
  overflow: scroll;
  max-height: 70px;
  display: block;
  padding: 10px;
  background-color: #72818e;
  color: #373737;
}
</style>
